import React, { useMemo, useState } from "react";
import Toast from "light-toast";
import { syncUserWishlists, transferUserCredits } from "../../../actions/userAction";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { UserAccountSync } from "models/UserAccountSync";
import { LoadingState } from "../ProfileComponents";
import { roundToDecimalPlaces } from "utils/stringUtils";
import TransferCreditButton from "./TransferCreditButton";
import MergeWishlistButton from "./MergeWishlistButton";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { UserAccountSyncItemLastMergedInterface } from "models/user";
import { convertTimestampToNumber } from "utils/dateUtils";

interface AccountSyncRowProps {
  accountSync: UserAccountSync;
  cachedUserAccountSyncItem: UserAccountSyncItemLastMergedInterface | undefined;
  isSynced: boolean;
  styles?: React.CSSProperties;
  setIsPerformingSync: (isSyncing: boolean) => void;
  disableOverride?: boolean;
  onWishlistSynced?: (accountSync: UserAccountSync) => void;
}

const componentStyles = makeStyles(() => ({
  img: {
    width: 16,
    height: 16
  }
}));

const AccountSyncRow: React.FC<AccountSyncRowProps> = ({
  accountSync,
  cachedUserAccountSyncItem,
  styles,
  setIsPerformingSync,
  disableOverride,
  onWishlistSynced
}) => {
  const classes = componentStyles();
  const { isMobile } = useMediaBreakpoints();

  const [isSynced, setIsSynced] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isSyncingWishlists, setIsSyncingWishlists] = useState<boolean>(false);
  const [isWishlistsSynced, setIsWishlistsSynced] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<number>(accountSync.credit_balance);
  const [wishlistCount, setWishlistCount] = useState<number>(accountSync.wishlist_count);

  const syncAccount = async () => {
    setIsSyncing(true);
    setIsPerformingSync(true);
    try {
      await transferUserCredits({
        sourceChannelPartnerId: accountSync.channel_partner_id,
        sourceUserId: accountSync.user_id
      });
      setIsSynced(true);
      setCreditAmount(0);
    } catch (err) {
      Toast.fail("Failed to sync account");
    } finally {
      setIsSyncing(false);
      setIsPerformingSync(false);
    }
  };

  const syncWishlists = async () => {
    setIsSyncingWishlists(true);
    setIsPerformingSync(true);
    try {
      await syncUserWishlists({
        sourceChannelPartnerId: accountSync.channel_partner_id,
        sourceUserId: accountSync.user_id
      });
      setIsWishlistsSynced(true);
      setWishlistCount(0);
      onWishlistSynced(accountSync);
    } catch (err) {
      Toast.fail("Failed to sync account");
    } finally {
      setIsSyncingWishlists(false);
      setIsPerformingSync(false);
    }
  };

  const canSync = useMemo(() => {
    return creditAmount > 0;
  }, [creditAmount]);

  const canSyncWishlist = useMemo(() => {
    return wishlistCount > 0;
  }, [wishlistCount]);

  const hasAlreadySynced = useMemo(() => {
    if (cachedUserAccountSyncItem) {
      if (
        cachedUserAccountSyncItem &&
        cachedUserAccountSyncItem.wishlistItemsLastAdded >=
          convertTimestampToNumber(accountSync.wishlist_items_last_added)
      ) {
        return true;
      }
    }

    return false;
  }, [cachedUserAccountSyncItem]);

  const isDisabled = useMemo(() => {
    return !canSync || disableOverride;
  }, [canSync, disableOverride]);

  const isSyncWishlistsDisabled = useMemo(() => {
    return !canSyncWishlist || disableOverride;
  }, [canSyncWishlist, disableOverride]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: isMobile ? undefined : 560,
        height: 52,
        width: isMobile ? "100%" : undefined,
        ...styles
      }}
    >
      <img
        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${accountSync.channel_partner_url}`}
        alt=""
        className={classes.img}
      />
      <Typography variant="caption" style={{ marginLeft: 8, minWidth: isMobile ? undefined : 230 }}>
        {accountSync.channel_partner_store_name}
        {accountSync.user_nickname ? `\n(${accountSync.user_nickname})` : ""}
      </Typography>
      {isMobile ? <Box style={{ flex: 1, minWidth: 8 }} /> : <Box style={{ width: 16 }} />}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center"
        }}
      >
        {isSyncing ? (
          <LoadingState size={34} styles={{ width: 124 }} />
        ) : isSynced ? (
          <Typography variant="body2" style={{ textAlign: "center", width: 124 }}>
            Transferred
          </Typography>
        ) : (
          <TransferCreditButton
            text={"$" + roundToDecimalPlaces(creditAmount.toString(), 2)}
            secondaryText={"Transfer Credit"}
            disabled={isDisabled}
            handleClick={syncAccount}
            style={{ width: 124 }}
          />
        )}
      </Box>
      <Box style={{ width: 16 }} />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center"
        }}
      >
        {isSyncingWishlists ? (
          <LoadingState size={34} styles={{ width: 124 }} />
        ) : (isWishlistsSynced || hasAlreadySynced) ? (
          <Typography variant="body2" style={{ textAlign: "center", width: 124 }}>
            Merged
          </Typography>
        ) : (
          <MergeWishlistButton
            text={`${wishlistCount} item${wishlistCount === 1 ? "" : "s"}`}
            secondaryText={"Merge Wishlist"}
            disabled={isSyncWishlistsDisabled}
            handleClick={syncWishlists}
            style={{ width: 124 }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AccountSyncRow;
