import { UserAccountSyncItemLastMergedInterface } from "models/user";

interface Recipient {
  nickname: string;
  giftCount: number;
  userId: string;
}

const LOCAL_STORAGE_KEY_RECIPIENTS = "recipients";
const LOCAL_STORAGE_KEY_AUTHENTICATED_USER_ID = "authenticatedUserId";
const LOCAL_STORAGE_KEY_SELECTED_RECIPIENT = "selectedRecipient";
const LOCAL_STORAGE_KEY_SIGN_UP_EVENT = "signUpEvent";
const LOCAL_STORAGE_KEY_GIFT_SEARCHES = "giftSearches";
const LOCAL_STORAGE_KEY_USER_FIRST_TIME_LOGIN = "userFirstTimeLogin";
const LOCAL_STORAGE_KEY_USER_ACCOUNT_SYNC_ITEM_LAST_MERGED = "userAccountSyncItemLastMerged";

// Update one of the items in the array and then save the entire array again to localStorage
export function updateUserAccountSyncItem(updatedItem: UserAccountSyncItemLastMergedInterface) {
  const items: UserAccountSyncItemLastMergedInterface[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER_ACCOUNT_SYNC_ITEM_LAST_MERGED)) ?? [];
  const itemIndex = items.findIndex(item => item.userId === updatedItem.userId);

  if (itemIndex !== -1) {
    // Update existing item
    items[itemIndex] = updatedItem;
  } else {
    // Add new item
    items.push(updatedItem);
  }

  localStorage.setItem(LOCAL_STORAGE_KEY_USER_ACCOUNT_SYNC_ITEM_LAST_MERGED, JSON.stringify(items));
}

// Retrieve the items from localStorage
export function getUserAccountSyncItems(): UserAccountSyncItemLastMergedInterface[] {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER_ACCOUNT_SYNC_ITEM_LAST_MERGED)) ?? [];
}

export function getRecipients() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_RECIPIENTS));
}

export const updateRecipientsToLocalStorage = (recipient: Recipient) => {
  if (recipient.nickname && recipient.userId) {
    const currentRecipients = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_RECIPIENTS)) ?? [];
    const cleanRecipients = currentRecipients.filter((item: Recipient) => item.userId !== recipient.userId);
    const finalRecipients = [...cleanRecipients, { ...recipient }];
    localStorage.setItem(LOCAL_STORAGE_KEY_RECIPIENTS, JSON.stringify(finalRecipients));
  }
};

export function setAuthenticatedUserId(user) {
  localStorage.setItem(LOCAL_STORAGE_KEY_AUTHENTICATED_USER_ID, JSON.stringify(user));
}

export function getAuthenticatedUserId() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_AUTHENTICATED_USER_ID));
}

export function getSelectedRecipient() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_SELECTED_RECIPIENT));
}

export function setSelectedRecipient(recipient) {
  localStorage.setItem(LOCAL_STORAGE_KEY_SELECTED_RECIPIENT, JSON.stringify(recipient));
}

export function addItemToRecipients(newRecipient) {
  if (!newRecipient || !newRecipient.userId || !newRecipient.nickname) {
    return;
  }

  const recipients = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_RECIPIENTS));
  if (!recipients || !recipients.length) {
    localStorage.setItem(LOCAL_STORAGE_KEY_RECIPIENTS, JSON.stringify([newRecipient]));
    return;
  }

  const filteredRecipients = recipients.filter((recipient) => recipient.userId !== newRecipient.userId);
  filteredRecipients.push(newRecipient);

  localStorage.setItem(LOCAL_STORAGE_KEY_RECIPIENTS, JSON.stringify(filteredRecipients));
}

export function setSignUpEvent(social) {
  localStorage.setItem(LOCAL_STORAGE_KEY_SIGN_UP_EVENT, social);
}

export function getSignUpEvent() {
  return localStorage.getItem(LOCAL_STORAGE_KEY_SIGN_UP_EVENT);
}

export function deleteSignUpEvent() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_SIGN_UP_EVENT);
}

export function getGiftSearches(): Array<string> {
  const giftSearches = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_GIFT_SEARCHES));
  return giftSearches ?? [];
}

export function addItemToGiftSearches(searchString: string): void {
  if (!searchString || !searchString.length) {
    return;
  }

  const giftSearches = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_GIFT_SEARCHES));
  if (!giftSearches || !giftSearches.length) {
    localStorage.setItem(LOCAL_STORAGE_KEY_GIFT_SEARCHES, JSON.stringify([searchString]));
    return;
  }

  giftSearches.push(searchString);
  localStorage.setItem(LOCAL_STORAGE_KEY_GIFT_SEARCHES, JSON.stringify(giftSearches));
}

export function clearLocalStorageWhenLogout() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_RECIPIENTS);
  localStorage.removeItem(LOCAL_STORAGE_KEY_AUTHENTICATED_USER_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEY_SELECTED_RECIPIENT);
  localStorage.removeItem(LOCAL_STORAGE_KEY_SIGN_UP_EVENT);
  localStorage.removeItem(LOCAL_STORAGE_KEY_GIFT_SEARCHES);
  localStorage.removeItem(LOCAL_STORAGE_KEY_USER_FIRST_TIME_LOGIN);
  localStorage.removeItem(LOCAL_STORAGE_KEY_USER_ACCOUNT_SYNC_ITEM_LAST_MERGED);
}

export function removeUserFirstTimeLogin() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_USER_FIRST_TIME_LOGIN);
}